<template>
  <div>
    <div
      class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      "
    >
      <h1 class="h2">Channels</h1>
      <button class="btn btn-primary" @click="$router.push('/channels/new')">
        New Channel
      </button>
    </div>
    <div>
      <div class="form-inline mb-2">
        <div class="form-group mr-2">
          <autocomplete
            class="form-control"
            source="https://api.kolel.org/channels/autocomplete.json?term="
            results-property="items"
            @selected="filterChannel"
            :request-headers="authHeaders"
            results-display="name"
          >
          </autocomplete>
        </div>
        <div class="form-group mr-2">
          <label class="control-label mr-1">Is Hot</label>
          <select class="form-control" @change="getData()" v-model="filterHot">
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        <div class="form-group mr-2">
          <label class="control-label mr-1">O&O</label>
          <select class="form-control" @change="getData()" v-model="filterOno">
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
      </div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Image</th>
            <th
              @click="changeSort('title')"
              :class="sortBy == 'title' ? 'sorted' : ''"
            >
              Name
              <i
                :class="`fas fa-sort-${sortDir == 'desc' ? 'down' : 'up'}`"
                v-if="sortBy == 'title'"
              ></i>
            </th>
            <th
              @click="changeSort('followers')"
              :class="sortBy == 'followers' ? 'sorted' : ''"
            >
              Followers
              <i
                :class="`fas fa-sort-${sortDir == 'desc' ? 'down' : 'up'}`"
                v-if="sortBy == 'followers'"
              ></i>
            </th>
            <th>O & O</th>
            <th>Is Hot?</th>
            <th>Videos</th>
            <th>Last Published</th>
            <th>Join Date</th>
            <th>Contacts</th>
            <th>Page Views</th>
            <th
              @click="changeSort('views')"
              :class="sortBy == 'views' ? 'sorted' : ''"
            >
              Video Views
              <i
                :class="`fas fa-sort-${sortDir == 'desc' ? 'down' : 'up'}`"
                v-if="sortBy == 'views'"
              ></i>
            </th>
            <th
              @click="changeSort('visits')"
              :class="sortBy == 'visits' ? 'sorted' : ''"
            >
              Channel Visits
              <i
                :class="`fas fa-sort-${sortDir == 'desc' ? 'down' : 'up'}`"
                v-if="sortBy == 'visits'"
              ></i>
            </th>
            <th
              @click="changeSort('shares')"
              :class="sortBy == 'shares' ? 'sorted' : ''"
            >
              Video Shares
              <i
                :class="`fas fa-sort-${sortDir == 'desc' ? 'down' : 'up'}`"
                v-if="sortBy == 'shares'"
              ></i>
            </th>
            <th
              @click="changeSort('user_duration')"
              :class="sortBy == 'user_duration' ? 'sorted' : ''"
            >
              Avg Watch Time Per User
              <i
                :class="`fas fa-sort-${sortDir == 'desc' ? 'down' : 'up'}`"
                v-if="sortBy == 'user_duration'"
              ></i>
            </th>
            <th
              @click="changeSort('duration')"
              :class="sortBy == 'duration' ? 'sorted' : ''"
            >
              Total Watch Time
              <i
                :class="`fas fa-sort-${sortDir == 'desc' ? 'down' : 'up'}`"
                v-if="sortBy == 'duration'"
              ></i>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="channel.id" v-for="channel in channels">
            <td>
              <img :src="channel.image" class="channel-image" />
            </td>
            <td>
              {{ channel.name }}
            </td>
            <td>
              {{ channel.followers_count }}
            </td>
            <td>
              {{ channel.o_n_o ? "Y" : "" }}
            </td>
            <td>
              {{ channel.is_hot ? "Y" : "" }}
            </td>
            <td>
              <router-link :to="`/videos?channel_id=${channel.id}`">{{
                channel.videos_count
              }}</router-link>
            </td>
            <td>
              {{ moment(channel.last_published).fromNow() }}
            </td>
            <td>
              {{ moment(channel.created_at).format("DD/MM/YYYY") }}
            </td>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              {{ channel.user_watches_count }} (+{{ channel.dummy_watches }})
            </td>
            <td>
              {{ channel.channel_visits }}
            </td>
            <td>
              {{ channel.channel_shares }}
            </td>
            <td>
              {{ channel.watch_duration[1].toString().toHHMMSS(true) }}
            </td>
            <td>
              {{ channel.watch_duration[0].toString().toHHMMSS(true) }}
            </td>
            <td>
              <button
                @click="
                  $router.push({ name: 'Channel', params: { id: channel.id } })
                "
              >
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <paginate
        :page-count="Math.ceil(total / limit)"
        :page-range="3"
        :margin-pages="2"
        :click-handler="getData"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      >
      </paginate>
      Total: {{ total }}
    </div>
  </div>
</template>

<script>
import Autocomplete from "vuejs-auto-complete";
import moment from "moment";
import numeral from "numeral";

export default {
  data() {
    return {
      channels: null,
      moment: moment,
      numeral: numeral,
      limit: 10,
      total: 0,
      filterHot: "",
      filterOno: "",
      sortBy: "title",
      sortDir: "asc",
    };
  },
  components: {
    Autocomplete,
  },
  watch: {
    sortBy: {
      handler: function () {
        this.getData();
      },
    },
    sortDir: {
      handler: function () {
        this.getData();
      },
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    authHeaders() {
      return {
        Token: localStorage.getItem("token"),
      };
    },
  },
  methods: {
    changeSort(field) {
      if (this.sortBy == field)
        this.sortDir = this.sortDir == "desc" ? "asc" : "desc";
      this.sortBy = field;
    },
    filterChannel(channel) {
      this.getData(0, channel.value);
    },
    getData(page, channel_id) {
      page = page || 1;
      this.$http
        .get(
          `/channels.json?o_n_o=${this.filterOno}&is_hot=${
            this.filterHot
          }&channel_id=${channel_id || ""}&limit=${this.limit}&offset=${
            (page - 1) * this.limit
          }&sort_by=${this.sortBy}&sort_dir=${this.sortDir}`
        )
        .then((res) => {
          this.channels = res.data.channels;
          this.total = res.data.total;
        });
    },
  },
};
</script>
<style scoped>
.channel-image {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

th {
  white-space: nowrap;
}

th.sorted {
  background: #eee;
}
</style>