<template>
  <div id="chart">
    <apexchart
      height="500px"
      type="line"
      :options="userOptions"
      :series="seriesUser"
      class="chart"
    ></apexchart>
    <apexchart
      height="500px"
      type="line"
      :options="viewsOptions"
      :series="seriesViews"
      class="chart"
    ></apexchart>
    <apexchart
      height="500px"
      type="line"
      :options="durationOptions"
      :series="seriesDuration"
      class="chart"
    ></apexchart>
  </div>
</template>
<script>
import moment from "moment";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Chart",
  components: {
    apexchart: VueApexCharts,
  },
  data: function () {
    window.moment = moment;
    return {
      seriesUser: [],
      seriesViews: [
        {
          name: "a",
          data: [],
        },
      ],
      seriesDuration: [],
      userOptions: {
        title: {
          text: "New Users",
          align: "center",
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "MMM dd",
          },
        },
        yaxis: {
          tickAmount: 1,
        },
      },
      viewsOptions: {
        title: {
          text: "Video views",
          align: "center",
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "MMM dd",
          },
        },
        yaxis: {
          tickAmount: 1,
        },
      },
      durationOptions: {
        title: {
          text: "Video Duration",
          align: "center",
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "MMM dd",
          },
        },
        yaxis: {
          tickAmount: 1,
          labels: {
            formatter: (value) => {
              return value + " minutes";
            },
          },
        },
      },
      moment: moment,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let currentTime = moment();
      let start_of_the_lastest_30_days = currentTime
          .startOf("day")
          .subtract(30, "days")
          .unix(),
        utc_offset = currentTime.utcOffset() * 60,
        params_new_users = {
          start_of_the_lastest_30_days: start_of_the_lastest_30_days,
          utc_offset: utc_offset,
          is_anonymous: false,
        },
        params_anonymous = {
          start_of_the_lastest_30_days: start_of_the_lastest_30_days,
          utc_offset: utc_offset,
          is_anonymous: true,
        },
        params_video_data = {
          start_of_the_lastest_30_days: start_of_the_lastest_30_days,
          utc_offset: utc_offset,
        };

      this.$http
        .get(`/admins/dashboard/users_data.json`, {
          params: params_new_users,
        })
        .then(this.updateUserData);

      this.$http
        .get(`/admins/dashboard/users_data.json`, {
          params: params_anonymous,
        })
        .then(this.updateUserData);

      this.$http
        .get(`/admins/dashboard/video_views_data.json`, {
          params: params_video_data,
        })
        .then(this.updateViewsData);

      this.$http
        .get(`/admins/dashboard/video_duration_data.json`, {
          params: params_video_data,
        })
        .then(this.updateDurationData);
    },
    updateUserData(res) {
      this.seriesUser.push({
        name: this.seriesUser.length ? "Anonymous Users" : "New Users",
        data: res.data.yaxis,
      });
      this.userOptions = {
        xaxis: {
          categories: res.data.xaxis,
        },
      };
    },
    updateViewsData(res) {
      this.seriesViews = [
        {
          name: "Video Views",
          data: res.data.yaxis,
        },
      ];
      this.viewsOptions = {
        xaxis: {
          categories: res.data.xaxis,
        },
      };
    },
    updateDurationData(res) {
      this.seriesDuration = [
        {
          name: "Video Duration",
          data: res.data.yaxis,
        },
      ];
      this.durationOptions = {
        xaxis: {
          categories: res.data.xaxis,
        },
      };
    },
  },
};
</script>
<style scoped>
.chart {
  margin: 5rem 0;
}
</style>