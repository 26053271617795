<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<h1 class="h2">Videos</h1>
		</div>
		<div>
			<div class="form-inline mb-2">
				<div class="form-group mr-2">
					<label class="control-label mr-1">Channel</label><button @click="clearChannel()" class="btn btn-sm ml-2 btn-primary">Clear</button>					
					<input type="text" disabled="disabled" class="form-control" v-model="channel.name" />
					
				</div>
				<div class="form-group mr-2">
					<label class="control-label mr-1">Status</label>					
					<select class="form-control" @change="getData()" v-model="filter">						
						<option value="1">Approved</option>
						<option value="2">Rejected</option>
					</select>					
				</div>
				<div class="form-group mr-2">
					<label class="control-label mr-1">Title</label>					
					<input class="form-control" type="text" v-model="searchVideoTerm" />
					
				</div>
				<div class="form-group mr-2">
					<label class="control-label mr-1">Video External ID</label>					
					<input class="form-control" type="text" v-model="searchVideoExtID" />					
				</div>
			</div>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>
							ID
						</th>
						<th>
							Preview
						</th>
						<th>
							Actions
						</th>
						<th>
							Caption
						</th>
						<th>
							Channel
						</th>
						<th>
							Duration
						</th>
						<th>
							Platform
						</th>
						<th>
							Approved?
						</th>
						<th>
							Publish Time
						</th>
						<th>
							External ID
						</th>
						<th>
							Shares
						</th>
						<th>
							Views
						</th>
						<th>
							Avg Watch Time Per User
						</th>
						<th>
							Total Watch Time
						</th>
					</tr>
				</thead>
				<tbody>
					<tr :key="video.id" v-for="video in videos">
						<td>
							{{ video.id }}
						</td>
						<td>
							<div @click="selectedPost = video" :style="`background-image: url(${video.thumb_url})`" class="video-image d-flex align-items-center justify-content-center">
								<i class="fas fa-play fa-3x" style="color: white"></i>
							</div>
						</td>
						<td>
							<button v-if="!video.isEditing" @click="startEdit(video)">Edit</button>
							<button v-else @click="cancelEdit(video)">Cancel</button>
							<button v-if="video.isEditing" @click="saveEdit(video)">Save</button>

							<button v-if="!video.isEditing" @click="setStatus(video, false)">Approve</button>
							<button v-if="!video.isEditing" @click="setStatus(video, true)">Reject</button>
							<button v-if="!video.isEditing" @click="downloadWithAxios(video)">Download</button>
							<button v-if="!video.isEditing" :id="`video-${video.id}`">Add to Section</button>
							<button v-if="!video.isEditing" :id="`change-channel-${video.id}`">Change channel</button>

							<b-popover
							:target="'video-' + video.id"
							triggers="focus"
							placement="auto">
							<div>
								<b-form-group
								label="Section"
								label-for="popover-input-1"
								label-cols="5"
								class="mb-1"
								invalid-feedback="This field is required"
								>
								<b-form-select
								size='sm'
								v-model="videoPosition['category_id']"
								:options="categories"
								value-field="id"
								text-field="name"></b-form-select>
							</b-form-group>

							<b-form-group
							label="Position"
							label-for="popover-input-1"
							label-cols="5"
							class="mb-1"
							invalid-feedback="This field is required"
							>
							<b-form-select
							size='sm'
							v-model="videoPosition['position']"
							:options="positions"></b-form-select>
						</b-form-group>

						<div class="d-flex flex-row justify-content-between">
							<b-button @click="onAddVideoToSection(video)" size="sm" variant="primary"
							>Add</b-button
							>
						</div>
					</div>
				</b-popover>

				<b-popover
				ref="changeChannelPopover"
				:target="`change-channel-${video.id}`"
				triggers="focus"
				placement="auto">
				<div>
					<b-form-group
					label="Move to channel"
					label-for="popover-input-1"
					label-cols="5"
					class="mb-1"
					invalid-feedback="This field is required"
					>
					<b-form-select
					size='sm'
					v-model="changeToChannel"
					:options="ownChannels"
					value-field="id"
					text-field="name"></b-form-select>
				</b-form-group>

				<div class="d-flex flex-row justify-content-between">
					<b-button @click="changeChannel(video)" size="sm" variant="primary">Change</b-button>
				</div>
			</div>
		</b-popover>
	</td>
	<td>
		<span v-if="!video.isEditing">{{ video.title }}</span>
		<textarea class="form-control" v-else v-model="video.title" />
	</td>
	<td>
		{{ video.channel.name }}
	</td>
	<td>
		{{ video.duration ? video.duration.toString().toHHMMSS() : 0 }}
	</td>
	<td>
		{{ video.platform }}
	</td>
	<td>
		{{ video.approved }}
	</td>
	<td>
		{{ moment(video.publish_time).format("DD/MM/YYYY") }}
	</td>
	<td>
		{{ video.external_id }}
	</td>
	<td>
		{{ video.shares }}
	</td>
	<td>
		{{ video.user_watches_count }}
	</td>
	<td>
		{{ video.watch_duration[1].toString().toHHMMSS(true) }}
	</td>
	<td>
		{{ video.watch_duration[0].toString().toHHMMSS(true) }}
	</td>
</tr>
</tbody>
</table>
<paginate
:page-count="Math.ceil(total / limit)"
:page-range="3"
:margin-pages="2"
:click-handler="getData"
:prev-text="'Prev'"
:next-text="'Next'"
:container-class="'pagination'"
:page-class="'page-item'">
</paginate>
Total: {{ total }}
</div>
<b-modal size="md" id="post-modal" title="">
	<template v-if="selectedPost">
		<h4>
			{{ selectedPost.title }}
		</h4>
		<img class='modal-img' :src="selectedPost.image_url" />
	</template>
</b-modal>
</div>
</template>

<script>
	import moment from 'moment'
	import numeral from 'numeral'
	import Axios from 'axios'
	import eventHub from '../eventHub'	

	export default {
		data() {
			return {
				changeToChannel: 0,
				ownChannels: [],
				searchVideoTerm: '',
				searchVideoExtID: '',
				cancelSource: null,
				channel_id: null,
				channel: {},
				filter: 1,
				videos: null,
				moment: moment,
				numeral: numeral,
				limit: 10,
				total: 0,
				page: 1,
				selectedPost: null,				
				videoPosition: {},
				categories: [],
				positions: [...Array(25).keys()].map(p => p + 1)
			}
		},
		components: {
			
		},
		updated()
		{

		},
		watch: {
			searchVideoTerm: {
				handler: function () {
					this.getData();
				}
			},
			searchVideoExtID: {
				handler: function () {
					this.getData();
				}
			},
			'$route.query.channel_id': {
				handler: function (val) {
					this.channel_id = val;
					this.getData();
				},
				immediate: true
			},
			selectedPost: {
				handler: function (val) {					
					eventHub.$emit('play-video', val)
				}
			},		
			channel_id: {
				handler: function (val) {
					this.loadChannel(val);
					this.getData();
				},
			}
		},
		mounted() {
			this.channel_id = this.$route.query.channel_id;
			this.loadChannel(this.channel_id);
			this.getOwnChannels();
			this.getCategories()
		},
		methods: {
			getOwnChannels() {
				this.$http.get(`/channels.json?o_n_o=true&limit=100`).then(res => this.ownChannels = res.data.channels)
			},
			searchVideo(term)
			{
				if (this.cancelSource) {
					this.cancelSource.cancel();
				}

				this.cancelSource = Axios.CancelToken.source();

				if (!term)
					return;

				this.videos = null;

				this.$http.get(`/videos/search.json?term=${term}`, {cancelToken: this.cancelSource.token}).then(res => {
					this.cancelSource = null;
					this.videos = res.data.videos;
				})
			},
			loadChannel(channel_id)
			{
				this.videos = null;
				if (!channel_id)
					return;

				this.$http.get(`/channels/${channel_id}.json`).then(res => {
					this.channel = res.data.channels;
				})
			},
			forceFileDownload(response, video){
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${video.id}.mp4`)
				document.body.appendChild(link)
				link.click()
			},
			downloadWithAxios(video){
				this.$http({
					method: 'get',
					url: video.video_url,
					responseType: 'arraybuffer'
				})
				.then(response => {

					this.forceFileDownload(response, video)

				})
				.catch(() => console.log('error occured'))
			},
			clearChannel(){
				this.channel = {}
				this.channel_id = null;
			},
			saveEdit(video)
			{
				this.$http.put(`/videos/${video.id}.json`, video).then(() =>
				{
					this.getData(this.page);
				})
			},
			startEdit(video)
			{
				video.origTitle = video.title;
				video.isEditing = !video.isEditing;
				this.$forceUpdate();
			},
			cancelEdit(video)
			{
				video.isEditing = !video.isEditing;
				video.title = video.origTitle;
				this.$forceUpdate();
			},
			setStatus(video, status)
			{
				this.$http.put(`/videos/${video.id}.json`, { hidden: status }).then(() =>
				{
					this.getData(this.page);
				})
			},
			getCategories() {
				this.$http.get(`/categories.json`).then(res => this.categories = res.data.categories)
			},
			changeChannel(video)
			{				
				this.$http.put(`/videos/${video.id}.json`, {
					channel_id: this.changeToChannel
				}).then(() => {
					this.getData(this.page);
					this.$root.$emit('bv::hide::popover');
				});
			},
			onAddVideoToSection(video) {
				this.$http.post('/admins/video_positions', {
					video_id: video.id,
					category_id: this.videoPosition.category_id,
					position: this.videoPosition.position
				}).then(() => {
					this.$root.$emit('bv::hide::popover');
				});
			},
			getData(page) {
				if (this.cancelSource) {
					this.cancelSource.cancel();
				}

				this.cancelSource = Axios.CancelToken.source();

				page = page || 1;
				this.page = page;
				this.$http.get(`/videos.json?external_id=${this.searchVideoExtID}&term=${this.searchVideoTerm}&limit=${this.limit}&offset=${(page - 1) * this.limit}&filter=${this.filter}&channel_id=${this.channel_id || ''}`, {cancelToken: this.cancelSource.token}).then(res =>
				{
					this.videos = res.data.videos;
					this.total = res.data.total;
				})
			}
		}
	}
</script>
<style scoped>
textarea {
	width: 100%;
	height: 100%;
}

.video-image
{
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 200px;
	height: 120px;
}

.video-image > i
{
	display: none;
}

.video-image:hover > i
{
	display: inline-block;
}

.modal-img
{
	width: 100%;
}

.modal-body h4
{
	text-align: right;
}
</style>