<template>
	<form>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<h1 class="h2">New Video</h1>		
		</div>
		<form>			
			<div class="form-group">	
				<b-form-select
				size='sm'
				v-model="channel"
				:options="channels"
				value-field="id"
				text-field="name"></b-form-select>
				<small class="form-text text-muted">The channel to which the video will be uploaded</small>

			</div>
			<div class="form-group">	
				<label for="exampleInputEmail1">Link to video</label>
				<input type="text" class="form-control" v-model="link" placeholder="https://www.youtube.com/watch?v=98293829"/>
			</div>
			<div class="form-group">
				<label for="exampleInputEmail1">Title for video</label>
				<textarea v-model="title" />
				<small class="form-text text-muted">Optional caption that will be shown in the video</small>
			</div>
			<hr />
			<h2>Assign to section</h2>
			<b-form-select
			size='sm'
			v-model="category_id"
			:options="categories"
			value-field="id"
			text-field="name"></b-form-select>
			<div class="form-group">
				<label for="exampleInputEmail1">Section Section</label>
				<input type="number" class="form-control" v-model="position" max="25" />
				<small class="form-text text-muted">Optional position</small>
			</div>		
		</form>

		<button :disabled="!channel || !link || link == ''" class="btn btn-primary" @click="createVideo()">Create video</button>	
	</form>	
</template>

<script>	
	export default {
		data() {
			return {				
				title: '',
				channel: null,
				channels: [],
				link: null,
				position: 0,
				category_id: 0,
				categories: []
			}
		},
		watch: {

		},
		components:
		{
			
		},
		mounted() {
			this.getCategories()
			this.getOwnChannels()
		},
		computed:
		{
			authHeaders () {
				return {
					'Token': localStorage.getItem("token")
				}
			}
		},
		methods: { 
			getOwnChannels() {
				this.$http.get(`/channels.json?o_n_o=true&limit=100`).then(res => this.channels = res.data.channels)
			},
			getCategories() {
				this.$http.get(`/categories.json`).then(res => this.categories = res.data.categories)
			},
			filterChannel(channel)
			{
				this.channel = channel.value;
			},			
			createVideo()
			{
				this.$http.post(`/videos.json`, { video: { 					
					title: this.title, 
					channel_id: this.channel,
					link: this.link,
					category_id: this.category_id,
					position: this.position
				} }).then((res) => {
					if (res.data.status == 'exists')
					{
						alert('הסרטון קיים כבר במערכת');
						return;
					}
					else if (res.data.status == 'exists_and_positioned')
					{
						alert('הסכרון קיים אבל התווסף לקטגוריה הרלוונטית');
						return;
					}

					alert('הסרטון הוסף בהצלחה');
					this.title = null;
					this.channel_id = null;
					this.link = null;
					this.category_id = null;
					this.position = null;
				});
			},
			fileUploadCompleted: function(file, response) {								
				this.thumb = response.thumb;
			},
			processing: function(file) {
				this.startedFile = true;
				return file;
			}			
		}
	}
</script>
<style scoped>
textarea {
	width: 100%;
	height: 100%;
}

.video-image
{
	width: 200px;
	height: 120px;	
}

.modal-img
{
	width: 100%;
}

.modal-body h4
{
	text-align: right;
}
</style>