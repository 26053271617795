<template>
	<div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
		<div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">

			<div slot="no-body" class="full-page-bg-color">

				<div class="vx-row no-gutter justify-center items-center">

					<div class="vx-col hidden lg:block lg:w-1/2">

					</div>

					<div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
						<div class="px-8 pt-8 login-tabs-container d-flex flex-column justify-center">

							<div class="vx-card__title mb-4">
								<h4 class="mb-4">Login</h4>
								<p>Welcome back, please login to your account.</p>
							</div>
							<GoogleLogin :params="params" :renderParams="renderParams" :onSuccess="onSignInSuccess" :onFailure="onSignInError">Login</GoogleLogin>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>  
	import GoogleLogin from 'vue-google-login';

	export default {
		data () {
			return {      
				params: {
					client_id: '777113290198-3jf65nvesl1d3a5otoq3lb3v2eefkg5n.apps.googleusercontent.com',
				},
				renderParams: {
					width: 250,
					height: 50,
					longtitle: true
				}
			}
		},
		methods: {
			onSignInSuccess (googleUser) {
				this.$http.post(`/users/sso`, {
					token: googleUser.getAuthResponse().id_token
				}).then(res => {
					localStorage.setItem("token", res.data.token);
					let user = JSON.stringify(res.data.user);
					localStorage.setItem("user", user);        
					this.$http.defaults.headers.common['TOKEN'] = localStorage.getItem('token')
					this.$router.push( {name: 'Reports'} );
				})

			},
			onSignInError (error) {
				alert(error);
			}
		},
		components:{
			GoogleLogin
		}
	}
</script>

<style scoped>
.login-tabs-container {
	min-height: 505px;

	.con-tab {
		padding-bottom: 14px;
	}

	.con-slot-tabs {
		margin-top: 1rem;
	}
}
</style>
