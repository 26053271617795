<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<h1 class="h2">Reports</h1>		
		</div>
		<div>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>
							Preview
						</th>
						<th>
							Page Name
						</th>
						<th>
							Reporting User
						</th>
						<th>
							User Email
						</th>
						<th>
							Report Date
						</th>
						<th>
							Reason
						</th>
						<th>
							Action
						</th>
						<th>
							Action
						</th>						
					</tr>
				</thead>
				<tbody>
					<tr :key="report.id" v-for="report in reports">
						<td>
							<img :src="report.video.thumb_url" class="video-image" />
						</td>
						<td>
							{{ report.channel.name }}
						</td>
						<td>
							{{ report.user.name }}
						</td>
						<td>
							{{ report.user.email }}
						</td>
						<td>
							{{ moment(report.created_at).format("M/d/Y") }}
						</td>
						<td>
							{{ getReason(report.reason) }}
						</td>
						<td>
							<a target="_blank" :href="`https://youtube.com/watch?v=${report.video.external_id}`">Watch</a>
						</td>
						<td>
							<span @click="ignore(report)">Ignore</span>
						</td>				
					</tr>
				</tbody>
			</table>
		</div>	
	</div>	
</template>

<script>
	import moment from 'moment'
	import numeral from 'numeral'

	export default {
		data() {
			return {
				reports: null,
				moment: moment,
				numeral: numeral
			}
		},
		watch: {

		},
		mounted() {
			this.getData();
		},
		methods: { 
			ignore(report)
			{
				this.$http.put(`/video_reports/${report.id}.json`, { ignore: true }).then(() =>
				{
					this.reports = this.reports.filter(r => { return r.id != report.id })
				})
			},
			getReason(reason)
			{
				switch (reason) {
					case 1:
					return 'הפרת זכויות יוצרים'
					case 2:
					return 'מקדם אלימות או שנאה'
					case 3:
					return 'לא מתאים לאפליקציה'
					case 4:
					return 'תוכן ספאם'
					default:
					return 'לא ידוע'
				}
			},  
			getData() {
				this.$http.get('/video_reports.json?limit=10').then(res =>
				{
					this.reports = res.data.reports;
				})
			}
		}
	}
</script>
<style scoped>
.video-image
{
	width: 200px;
	height: 120px;	
}
</style>