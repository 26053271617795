<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<h1 class="h2">Newsletter</h1>		
		</div>
		<div>
			<table class="table table-bordered">
				<thead>
					<tr>						
						<th>
							Name
						</th>						
						<th>
							Email
						</th>
						<th>
							Phone
						</th>
						<th>
							Gender
						</th>
						<th>
							Submitted
						</th>						
					</tr>
				</thead>
				<tbody>
					<tr :key="user.id" v-for="user in users">
						<td>
							{{ user.name }}
						</td>
						<td>
							{{ user.email }}
						</td>
						<td>
							{{ user.phone }}
						</td>	
						<td>
							{{ user.gender }}
						</td>						
						<td>
							{{ moment(user.created_at).format("DD/MM/YYYY") }}
						</td>						
					</tr>
				</tbody>
			</table>
			<paginate
			:page-count="Math.ceil(total / limit)"
			:page-range="3"
			:margin-pages="2"
			:click-handler="getData"
			:prev-text="'Prev'"
			:next-text="'Next'"
			:container-class="'pagination'"
			:page-class="'page-item'">
		</paginate>		
	</div>	
</div>	
</template>

<script>
	import moment from 'moment'
	import numeral from 'numeral'

	export default {
		data() {
			return {
				users: null,
				moment: moment,
				numeral: numeral,
				limit: 10,
				total: 0
			}
		},
		watch: {

		},
		mounted() {
			this.getData();
		},
		methods: {      
			getData(page) {
				page = page || 1;
				this.$http.get(`/newsletter_subscribes.json?limit=${this.limit}&offset=${(page - 1) * this.limit}`).then(res =>
				{
					this.users = res.data.subscriptions;
					this.total = res.data.total;
				})
			}
		}
	}
</script>
<style scoped>

</style>