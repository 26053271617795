<template>
  <div>
    <div
      class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      "
    >
      <h1 class="h2">Users</h1>
    </div>
    <div>
      <div class="form-inline mb-2">
        <div class="form-group mr-2">
          <label class="control-label mr-1">Email</label>
          <input class="form-control" type="text" v-model="searchEmail" />
        </div>
      </div>
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Id</th>
            <th>Username</th>
            <th>Anonymous?</th>
            <th>Email</th>
            <th>Joined</th>
            <th>Gender</th>
            <th>Signup Device</th>
			<th>Signup Provider</th>
            <th>App Version</th>
            <th>Channels Following</th>
            <th>Unique Views</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="user.id" v-for="user in users" @click="$router.push({name: 'EditUser', params: {id: user.id }});">
            <td>
              {{ user.id }}
            </td>
            <td>
              {{ user.name }}
            </td>
            <td>
              {{ user.is_anonymous ? "Y" : "" }}
            </td>
            <td>
              {{
                user.email.replace(
                  /^(.)(.*)(.@.*)$/,
                  (_, a, b, c) => a + b.replace(/./g, "*") + c
                )
              }}
            </td>
            <td>
              {{ moment(user.created_at).format("DD/MM/YYYY") }}
            </td>
            <td>
              {{ user.gender }}
            </td>
            <td>
              {{ user.device }}
            </td>
			<td>
              {{ user.sso_provider }}
            </td>
            <td>
              {{ user.app_version }}
            </td>
            <td>
              {{ user.follows }}
            </td>
            <td>
              {{ user.views }}
            </td>
          </tr>
        </tbody>
      </table>
      <paginate
        :page-count="Math.ceil(total / limit)"
        :page-range="3"
        :margin-pages="2"
        :click-handler="getData"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      >
      </paginate>
      Total: {{ total }} (Android: {{ total_android }}, iOS: {{ total_ios }},
      Web: {{ total_web }})
    </div>
  </div>
</template>

<script>
import moment from "moment";
import numeral from "numeral";

export default {
  data() {
    return {
      users: null,
      searchEmail: '',
      moment: moment,
      numeral: numeral,
      limit: 10,
      total: 0,
      total_android: 0,
      total_ios: 0,
      total_web: 0,
    };
  },
  watch: {
    searchEmail: {
      handler: function () {
        this.getData();
      },
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(page) {
      page = page || 1;
      this.$http
        .get(
          `/users.json?email=${this.searchEmail}&limit=${this.limit}&offset=${(page - 1) * this.limit}`
        )
        .then((res) => {
          this.users = res.data.users;
          this.total = res.data.total;
          this.total_android = res.data.total_android;
          this.total_ios = res.data.total_ios;
          this.total_web = res.data.total_web;
        });
    },
  },
};
</script>
<style scoped>
</style>