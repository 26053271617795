<template>
	<form>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<h1 class="h2">{{ channel.name }}</h1><img class="channel-icon" :src="channel.image" />
		</div>
		<form>
			<div class="form-group">
				<label for="exampleInputEmail1">Title</label>
				<input type="text" class="form-control" required v-model="channel.name" placeholder=""/>
			</div>
			<div class="form-group">
				<label for="exampleInputEmail1">Youtube Handle</label>
				<input type="text" class="form-control" required v-model="channel.yt_id" placeholder="x9fdjfidXs"/>
			</div>
			<div class="form-group">
				<label for="exampleInputEmail1">Facebook Handle</label>
				<input type="text" class="form-control" v-model="channel.fb_id" placeholder=""/>
			</div>
			<div class="form-group">
				<label for="exampleInputEmail1">Profile Image</label>
				<input type="text" class="form-control" required v-model="channel.image" placeholder=""/>
			</div>
			<div class="form-group">
				<label for="exampleInputEmail1">Is HOT?</label>
				<select class="form-control" v-model="channel.is_hot">
					<option value="true">Yes</option>
					<option value="false">No</option>
				</select>
				<small class="form-text text-muted">To appear in Hot section?</small>
			</div>			
		</form>

		<button class="btn btn-primary" @click="saveChannel()">Save Channel</button>	
	</form>	
</template>

<script>	
	export default {
		data() {
			return {
				channel: {}				
			}
		},
		
		watch: {
			$route() {
				this.loadChannel();
			}
		},
		components:
		{

		},
		mounted() {
			if (this.$route.params.id != 'new')
				this.loadChannel();			
		},
		computed:
		{
			authHeaders () {
				return {
					'Token': localStorage.getItem("token")
				}
			}
		},
		methods: { 
			loadChannel() {
				this.$http.get(`/channels/${this.$route.params.id}.json`).then(res => this.channel = res.data.channels)
			},
			saveChannel()
			{
				let isNew = this.$route.params.id == 'new';
				let serviceMethod = isNew ? this.$http.post : this.$http.put;
				let url = isNew ? '/channels.json' : `/channels/${this.channel.id}.json`;
				serviceMethod(url, 
				{ 
					channel: this.channel
				}).then(() => {

				});
			}
		}
	}
</script>
<style scoped>
.channel-icon
{
	height: 50px;
}
</style>