<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<h1 class="h2">New Post</h1>		
		</div>
		<form>
			<div class="form-group">				
				<label for="exampleInputEmail1">Title for post</label>
				<textarea v-model="title" />
				<small class="form-text text-muted">The caption that will be shown in the post</small>
			</div>
			<div class="form-group">				
				<label for="exampleInputEmail1">Image</label>
				<vue-dropzone ref="myVueDropzone" id="dropzone"
				@vdropzone-success="fileUploadCompleted" @vdropzone-processing="processing" :options="dropzoneOptions"></vue-dropzone>
				<small class="form-text text-muted">Make sure to upload optimized and small image</small>
			</div>	
			<div class="form-group">
				<label for="exampleInputEmail1">Schedule Time</label>
				<datetime zone="America/New_York" type="datetime" v-model="date"></datetime>
				<small class="form-text text-muted">Can be in the past or future</small>
			</div>
		</form>
		
		<button :disabled="(!thumb && startedFile) || title === ''" class="btn btn-primary" @click="createPost()">Create</button>	
	</div>	
</template>

<script>	
	import vue2Dropzone from 'vue2-dropzone'
	import 'vue2-dropzone/dist/vue2Dropzone.min.css'

	export default {
		data() {
			return {
				startedFile: false,
				date: null,
				thumb: null,
				title: '',
				dropzoneOptions: {
					acceptedFiles: "image/*",
					maxFilesize: 30,
					maxFiles: 1,
					timeout: 180000,
					url: `${this.$host}/posts/sign.json`,
					thumbnailWidth: 150,					
					headers: { Token: localStorage.getItem("token") }
				}				
			}
		},
		watch: {

		},
		components:
		{
			vueDropzone: vue2Dropzone
		},
		mounted() {			
		},
		methods: { 
			createPost()
			{
				this.$http.post(`/posts`, { post: { 
					publish_time: this.date, 
					title: this.title, 
					image_url: this.thumb 
				} }).then(() => {
					alert('פוסט הוסף בהצלחה');
					this.date = null;
					this.thumb = null;
					this.title = '';
					this.startedFile = false;
					this.$refs.myVueDropzone.dropzone.getAcceptedFiles().forEach((file) =>
					{
						this.$refs.myVueDropzone.removeFile(file);
					});
				});
			},
			fileUploadCompleted: function(file, response) {								
				this.thumb = response.thumb;
			},
			processing: function(file) {
				this.startedFile = true;
				return file;
			}			
		}
	}
</script>
<style scoped>
textarea {
	width: 100%;
	height: 100%;
}

.video-image
{
	width: 200px;
	height: 120px;	
}

.modal-img
{
	width: 100%;
}

.modal-body h4
{
	text-align: right;
}
</style>