<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<h1 class="h2">New Push</h1>		
		</div>
		<form>
			<div class="form-group">				
				<label for="exampleInputEmail1">Title</label>
				<input type="text" v-model="title" />
				<small class="form-text text-muted">The caption that will be shown in the push</small>
			</div>
			<div class="form-group">				
				<label for="exampleInputEmail1">Body</label>
				<input type="text" v-model="body" />
				<small class="form-text text-muted">The body that will be shown in the push</small>
			</div>
			<div class="form-group">				
				<label for="exampleInputEmail1">Video ID</label>
				<input type="text" v-model="video_id" />
				<small class="form-text text-muted">The video that will be shown in the push</small>
			</div>
		</form>
		
		<button :disabled="title === ''" class="btn btn-primary" @click="createPush()">Send!</button>	
	</div>	
</template>

<script>	
	

	export default {
		data() {
			return {
				body: '',
				title: '',
				video_id: ''
			}
		},
		watch: {

		},
		components:
		{
			
		},
		mounted() {			
		},
		methods: { 
			createPush()
			{
				this.$http.post(`/notifications`, { notification: { 
					body: this.body, 
					title: this.title,
					video_id: this.video_id				
				} }).then(() => {
					alert('sent!')
				});
			},		
		}
	}
</script>
<style scoped>

</style>