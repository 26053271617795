<template>
  <div>
    <div class="row category-header">
      <div class="col-md-3">
        <label class="col-xs-3 control-label">Section:</label>
        <select class="form-control mb-3" v-model="category_id">
          <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <img v-if="categories" class="category-image" :src="categoryImage" />
      </div>      
    </div>    
    <div class="row">
      <div
      v-for="(video, index) in videos"
      v-bind:video="video"
      v-bind:index="index"
      v-bind:key="video.id"
      class="video-wrapper p-2"
      >
      <div @click="selectedPost = video" :style="`background-image: url(${video.thumb_url})`" class="video-image d-flex align-items-center justify-content-center">
        <i class="fas fa-play fa-3x" style="color: white"></i>
      </div>
      <div class="video-title"><b>{{ video.channel.name }}: </b>{{ video.title }}</div>
      <div class="video-views">Views: {{ video.views }}</div>
      <div class="video-setting d-flex flex-row mt-2">          
        <span class="video-position-text">Position:</span>
        <span class="w-100 d-flex">
          <span class="video-position">
            <b-button :id="'popover-video-position-' + video.id" variant="light" ref="button">
              <font-awesome-layers full-width class="fa-2x">
                <font-awesome-icon :icon="video.video_position ? 'lock' : 'lock-open'" :style="{ color: video.video_position ? 'red' : 'black' }"/>
                <font-awesome-layers-text class="text-light" transform="down-3 shrink-8" :value="video.video_position ? video.video_position.position : index + 1" />
              </font-awesome-layers>
            </b-button>
            
            <b-popover
            :target="'popover-video-position-' + video.id"
            triggers="focus"
            placement="auto"
            :ref="'change-popover-'+ video.id">
            <div>
              <b-form-group
              label="Lock position"
              label-for="popover-input-1"
              label-cols="8"
              class="mb-1"
              invalid-feedback="This field is required">
              <b-form-input
              ref="input"
              id="popover-input"
              size="sm"
              autocomplete="off"
              v-model="positions[index]"
              ></b-form-input>              
            </b-form-group>
            <div class="d-flex flex-row justify-content-between">
              <b-button @click="onUpdatePosition(video, index)" size="sm" variant="primary" :id="'popover-change-btn' +index">Change</b-button>
              <b-button @click="onRemovePosition(video)" size="sm" variant="warning">Unlock</b-button>
            </div>
          </div>
        </b-popover>
      </span>
      <span class="video-hide ml-auto" v-if="!video.video_position">
        <b-button :id="'popover-video-hide-' + video.id" ref="button">
          <font-awesome-layers full-width class="fa-2x">
            <font-awesome-icon icon="calendar-times" :style="{ color: 'blue' }"/>            
          </font-awesome-layers>
        </b-button>
        <b-popover
        :target="'popover-video-hide-' + video.id"
        triggers="focus"
        placement="auto"
        :ref="'remove-popover-'+ video.id">
        <div class="text-center">
          <b class="mb-3">Are you sure you want to remove this video from the feed ?</b>
          <div class="d-flex flex-row justify-content-between pt-4">
            <b-button size="sm" variant="primary" ref="button" @click="closePopover(video.id, 'remove-popover-')">Cancel</b-button>
            <b-button @click="removeVideoFromFeed(video)" size="sm" variant="danger" :id="'popover-change-btn' +index">Yes</b-button>
          </div>
        </div>
      </b-popover>
    </span>
  </span>
</div>
</div>
</div>
</div>
</template>

<script>
  import eventHub from '../eventHub'  
  export default {
    data() {
      return {
        limit: 25,
        offset: 0,
        category_id: 0,
        videos: [],
        res: [],
        positions: [],
        popoverShow: [],
        selectedPost: null,
        categories: null,
        updatePosition: null,
      };
    },
    watch: {
      selectedPost: {
        handler: function (val) {         
          eventHub.$emit('play-video', val)
        }
      },
      category_id: {
        handler: function () {
          this.$router.replace({query: { category_id: this.category_id }});
          this.getData();
        }
      }
    },
    updated() {},
    mounted() {
      if (this.$route.query.category_id)
        this.category_id = this.$route.query.category_id;
      this.getCategories();      
    },
    computed: 
    {
      categoryImage()
      {
        return this.categories.filter(c => c.id == this.category_id)[0].image;
      }
    },
    methods: {
      getCategories() {
        this.$http.get(`/categories.json`).then(res => { 
          this.categories = res.data.categories

          if (this.category_id === 0)
            this.category_id = this.categories[0].id;
        }
        )
      },
      getData() {
        this.$http.get(`/admins/video_positions?category_id=${this.category_id}&limit=${this.limit}&offset=${this.offset}`)
        .then((res) => {
          this.videos = res.data.videos;
          this.positions = Array.from({length: this.videos.length}, (_, i) => i + 1);
          this.popoverShow = Array.from({length: this.videos.length}, () => false);
        });
      },
      closePopover(index, popoverName) {
        this.$refs[popoverName + index][0].doClose()
      },
      onUpdatePosition(video, index) {
        if (video.video_position)
        {
          this.$http.put(`/admins/video_positions/${video.video_position.id}?new_position=${this.positions[index]}`)
          .then(() => {
            this.getData()
          });
        }
        else
        {
          this.$http.post('/admins/video_positions', {
            video_id: video.id,
            category_id: this.category_id,
            position: index + 1
          }).then(() => {
            this.getData()
          });
        }
      },
      onRemovePosition(video) {
        this.$http.delete(`/admins/video_positions/${video.video_position.id}`)
        .then(() => {
          this.getData()
        });
      },
      removeVideoFromFeed(video) {
        this.$http.delete(`/videos/${video.id}/remove_from_category.json?category_id=${this.category_id}`)
        .then(() => {
          this.getData()
        });
      }
    }
  };
</script>
<style scoped>
.video-wrapper {
  width: 20%;
}
video {
  width: 100%;
  height: 250px;
  box-shadow: 0px 0px 7px;
}

button {
  border: 0;
}

.video-position button {
  width: 35px;
  height: 35px;
}

.video-position-text {
  font-size: 16px;
  margin-right: 10px;
  align-self: center;
}

.video-hide {
  position: relative;
  right: 0;
}

.video-hide button {
  background-color: #fff;
  color: #000;
  padding: 7px;
  font-size: 15px;
  font-weight: bold;
}
.popover {
  background-color: gray;
  padding: 10px;
}

.popover-body {
  color: #fff;
}

.popover-body label {
  color: #fff;
}

.category-image
{
  border-radius: 50% 50%;
  width: 50px;  
  height: 50px;  
}

.category-header
{
  align-items: center;
}

.video-image
{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 200px;
  height: 120px;
}

.video-image > i
{
  display: none;
}

.video-image:hover > i
{
  display: inline-block;
}
</style>