<template>
  <div>
    <div
      class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      "
    >
      <h1 class="h2">Edit User - {{ user.email }}</h1>
    </div>
    <div class="col">
      <div class="form-group mr-2">
        <h2>Permissions</h2>
        <h5>Add Channel Permissions</h5>
        <autocomplete
          class="form-control"
          source="https://api.kolel.org/channels/autocomplete.json?term="
          results-property="items"
          @selected="filterChannel"
          :request-headers="authHeaders"
          results-display="name"
        >
        </autocomplete>
      </div>
      <div>
        <h5>Permitted Channels</h5>
        <table class="table">
          <tr :key="permission.id" v-for="permission in permissions">
            <td><button @click="changePermission({ value: permission.channel.id }, false)">Delete</button>{{ permission.channel.name }}</td>
          </tr>
        </table>
      </div>
    </div>
    <!-- <button class="btn btn-primary" @click="createPost()">Save</button> -->
  </div>
</template>

<script>
import Autocomplete from "vuejs-auto-complete";
export default {
  data() {
    return {
      user: {},
      permissions: [],
    };
  },
  watch: {},
  components: { Autocomplete },
  computed: {
    authHeaders() {
      return {
        Token: localStorage.getItem("token"),
      };
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    filterChannel(channel) {
      this.changePermission(channel, true);
    },
    changePermission(channel, addOrRemove) {
      this.$http
        .put(`/users/${this.user.id}/permissions.json`, {
          channel_id: channel.value,
          add_or_remove: addOrRemove,
        })
        .then((res) => {
          this.permissions = res.data.permissions;
        });
    },
    getData() {
      this.$http.get(`/users/${this.$route.params.id}.json`).then((res) => {
        this.user = res.data.user;

        this.$http
          .get(`/users/${this.user.id}/permissions.json`)
          .then((res) => {
            this.permissions = res.data.channels;
          });
      });
    },
  },
};
</script>
<style scoped>
</style>