<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<h1 class="h2">Posts</h1>		
		</div>
		<div>
			<div class="form-group">
				<label class="col-xs-3 control-label">Filter</label>
				<div class="col-xs-2">
					<select class="form-control mb-3" @change="getData()" v-model="filter">
						<option value="0">Pending</option>
						<option value="1">Approved</option>
						<option value="2">Rejected</option>
						<option value="3">Scheduled</option>
					</select>
				</div>
			</div>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>
							Preview
						</th>
						<th>
							Actions
						</th>
						<th>
							Caption
						</th>
						<th>
							Channel
						</th>
						<th>
							Approved?
						</th>
						<th>
							Publish Time
						</th>
					</tr>
				</thead>
				<tbody>
					<tr :key="post.id" v-for="post in posts">
						<td>
							<img @click="selectedPost = post" :src="post.image_url" class="video-image" v-b-modal.post-modal />
						</td>
						<td>
							<button v-if="!post.isEditing" @click="startEdit(post)">Edit</button>
							<button v-else @click="cancelEdit(post)">Cancel</button>
							<button v-if="post.isEditing" @click="saveEdit(post)">Save</button>

							<button @click="setStatus(post, true)">Approve</button>
							<button @click="setStatus(post, false)">Reject</button>
						</td>
						<td>
							<span v-if="!post.isEditing">{{ post.title }}</span>
							<textarea class="form-control" v-else v-model="post.title" />
						</td>
						<td>
							{{ post.channel.name }}
						</td>
						<td>							
							{{ post.approved }}
						</td>
						<td>
							{{ moment(post.publish_time).format("DD/MM/YYYY HH:mm") }}
						</td>
					</tr>
				</tbody>
			</table>
			<paginate
			:page-count="Math.ceil(total / limit)"
			:page-range="3"
			:margin-pages="2"
			:click-handler="getData"
			:prev-text="'Prev'"
			:next-text="'Next'"
			:container-class="'pagination'"
			:page-class="'page-item'">
		</paginate>
		Total: {{ total }}
	</div>	
	<b-modal size="md" id="post-modal" title="">
		<template v-if="selectedPost">
			<h4>
				{{ selectedPost.title }}
			</h4>
			<img class='modal-img' :src="selectedPost.image_url" />
		</template>
	</b-modal>
</div>	
</template>

<script>
	import moment from 'moment'
	import numeral from 'numeral'

	export default {
		data() {
			return {
				filter: 0,
				posts: null,
				moment: moment,
				numeral: numeral,
				limit: 10,
				total: 0,
				page: 1,
				selectedPost: null
			}
		},
		watch: {

		},
		mounted() {
			this.getData();
		},
		methods: { 
			saveEdit(post)
			{
				this.$http.put(`/posts/${post.id}.json`, post).then(() =>
				{					
					this.getData(this.page);				
				})
			},
			startEdit(post)
			{
				post.origTitle = post.title;
				post.isEditing = !post.isEditing; 
				this.$forceUpdate();
			},
			cancelEdit(post)
			{
				post.isEditing = !post.isEditing; 
				post.title = post.origTitle;
				this.$forceUpdate();
			},
			setStatus(post, status)
			{
				this.$http.put(`/posts/${post.id}.json`, { approved: status }).then(() =>
				{					
					this.getData(this.page);				
				})
			},			
			getData(page) {
				page = page || 1;
				this.page = page;
				this.$http.get(`/posts.json?limit=${this.limit}&offset=${(page - 1) * this.limit}&filter=${this.filter}`).then(res =>
				{
					this.posts = res.data.posts;
					this.total = res.data.total;
				})
			}
		}
	}
</script>
<style scoped>
textarea {
	width: 100%;
	height: 100%;
}

.video-image
{
	width: 200px;
	height: 120px;	
}

.modal-img
{
	width: 100%;
}

.modal-body h4
{
	text-align: right;
}
</style>