<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<h1 class="h2">Anonymous Feed</h1>		
		</div>
		<div>			
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>
							ID
						</th>
						<th>
							Preview
						</th>
						<th>
							Actions
						</th>
						<th>
							Caption
						</th>
						<th>
							Channel
						</th>
						<th>
							Duration
						</th>
						<th>
							Platform
						</th>
						<th>
							Approved?
						</th>
						<th>
							Publish Time
						</th>
					</tr>
				</thead>
				<tbody>
					<tr :key="video.id" v-for="video in videos">
						<td>
							{{ video.id }}
						</td>
						<td>
							<img @click="selectedPost = video" :src="video.thumb_url" class="video-image" v-b-modal.video-modal />
						</td>
						<td>
							<button v-if="!video.isEditing" @click="startEdit(video)">Edit</button>
							<button v-else @click="cancelEdit(video)">Cancel</button>
							<button v-if="video.isEditing" @click="saveEdit(video)">Save</button>

							<button @click="setStatus(video, true)">Hide from feed</button>							
							<button @click="downloadWithAxios(video)">Download</button>
						</td>
						<td>
							<span v-if="!video.isEditing">{{ video.title }}</span>
							<textarea class="form-control" v-else v-model="video.title" />
						</td>
						<td>
							{{ video.channel.name }}
						</td>
						<td>
							{{ video.duration.toString().toHHMMSS() }}
						</td>
						<td>
							{{ video.platform }}
						</td>
						<td>							
							{{ video.approved }}
						</td>
						<td>
							{{ moment(video.publish_time).format("DD/MM/YYYY") }}
						</td>
					</tr>
				</tbody>
			</table>
			<paginate
			:page-count="Math.ceil(total / limit)"
			:page-range="3"
			:margin-pages="2"
			:click-handler="getData"
			:prev-text="'Prev'"
			:next-text="'Next'"
			:container-class="'pagination'"
			:page-class="'page-item'">
		</paginate>
		Total: {{ total }}
	</div>	
	<b-modal size="md" id="post-modal" title="">
		<template v-if="selectedPost">
			<h4>
				{{ selectedPost.title }}
			</h4>
			<img class='modal-img' :src="selectedPost.image_url" />
		</template>
	</b-modal>
</div>	
</template>

<script>
	import moment from 'moment'
	import numeral from 'numeral'
	import Axios from 'axios'

	export default {
		data() {
			return {
				searchVideoTerm: '',	
				cancelSource: null,			
				channel_id: null,
				channel: {},
				filter: 1,
				videos: null,
				moment: moment,
				numeral: numeral,
				limit: 10,
				total: 0,
				page: 1,
				selectedPost: null
			}
		},
		updated()
		{
			
		},
		watch: {
			searchVideoTerm: {
				handler: function () {
					this.getData();
				}
			},
			'$route.query.channel_id': {
				handler: function (val) {
					this.channel_id = val;
					this.getData();
				},
				immediate: true				
			},
			channel_id: {
				handler: function (val) {
					this.loadChannel(val);
					this.getData();
				},				
			}
		},
		mounted() {
						
		},
		methods: { 						
			forceFileDownload(response, video){
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${video.id}.mp4`)
				document.body.appendChild(link)
				link.click()
			},
			downloadWithAxios(video){
				this.$http({
					method: 'get',
					url: video.video_url,
					responseType: 'arraybuffer'
				})
				.then(response => {

					this.forceFileDownload(response, video)

				})
				.catch(() => console.log('error occured'))
			},			
			saveEdit(video)
			{
				this.$http.put(`/videos/${video.id}.json`, video).then(() =>
				{					
					this.getData(this.page);				
				})
			},
			startEdit(video)
			{
				video.origTitle = video.title;
				video.isEditing = !video.isEditing; 
				this.$forceUpdate();
			},
			cancelEdit(video)
			{
				video.isEditing = !video.isEditing; 
				video.title = video.origTitle;
				this.$forceUpdate();
			},
			setStatus(video, status)
			{
				this.$http.put(`/videos/${video.id}.json`, { anonymous_hidden: status }).then(() =>
				{					
					this.getData(this.page);				
				})
			},			
			getData(page) {
				if (this.cancelSource) {
					this.cancelSource.cancel();
				}

				this.cancelSource = Axios.CancelToken.source();

				page = page || 1;
				this.page = page;
				this.$http.get(`/videos/recommended.json?term=${this.searchVideoTerm}&limit=${this.limit}&offset=${(page - 1) * this.limit}&filter=${this.filter}&channel_id=${this.channel_id || ''}`, {cancelToken: this.cancelSource.token}).then(res =>
				{
					this.videos = res.data.videos;
					this.total = res.data.total;
				}) 
			}
		}
	}
</script>
<style scoped>
textarea {
	width: 100%;
	height: 100%;
}

.video-image
{
	width: 200px;
	height: 120px;	
}

.modal-img
{
	width: 100%;
}

.modal-body h4
{
	text-align: right;
}
</style>